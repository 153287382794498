<template>
  <div>
    <va-card title="New Course">
      <vue-form-generator
        :schema="schema"
        :model="model"
        :options="formOptions"
      />
    </va-card>
  </div>
</template>


<script>
import VueFormGenerator from 'vue-form-generator';
import firebase from 'firebase';
import emptyCourse from '../../data/emptyCourse.json';
export default {
  name: 'NewCourse',
  components: {
    'vue-form-generator': VueFormGenerator.component,
  },
  data: function() {
    return {
      models: [],
      emptyCourse: emptyCourse,
      model: {
        name: '',
        patientModel: '',
        status: true,
      },

      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  components: {
    // OrbitSpinner,
  },
  computed: {
    schema() {
      const result = {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: 'Course Name',
            model: 'name',
            placeholder: 'Name of the course',
            featured: true,
            required: true,
          },
          {
            type: 'select',
            label: 'Patient Model',
            model: 'patientModel',
            values: this.models,
            featured: true,
            required: true,
            selectOptions: {
              noneSelectedText: 'Pick a model',
            },
          },
          {
            type: 'submit',
            buttonText: 'Create Course',
            validateBeforeSubmit: true,
            disabled: ()=>{
              return this.model.name === '' || this.model.patientModel === '';
            },
            onSubmit: this.createCourse,
          },
        ],
      };
      return result;
    },
  },
  async created() {
    const modelQuery = firebase.firestore().collection('model').where('Active', '==', true);

    modelQuery.get().then((querySnapshot) => {
      querySnapshot.forEach(async (doc) => {
        const data = doc.data();
        const model = {};
        model.id = doc.id;
        model.name = data.name;
        model.blueprint = data.blueprint;

        this.models.push(model);
      });
    });
  },
  methods: {
    createCourse() {
      const data = {
        Active: false,
        Deleted: false,
        Name: this.model.name,
        lessons: this.model.blueprint ? this.model.blueprint : this.emptyCourse,
        model: firebase.firestore().collection('model').doc(this.model.patientModel),
      };
      const router = this.$router;
      firebase.firestore().collection('course').add(data).then((response) => {
        router.push({path: 'course/' + response.id});
      });
    },
  },
  watch: {

  },

};
</script>
